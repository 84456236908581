<template>
	<Dropdown
		v-model="selectedCountry"
		:options="locales"
		filter
		option-label="name"
		class="change-lang">
		<template #value="slotProps">
			<div v-if="slotProps.value">
				<div>{{ slotProps.value.code }}</div>
			</div>

			<span v-else>
				{{ slotProps.placeholder }}
			</span>
		</template>

		<template #option="slotProps">
			<div>
				{{ slotProps.option.name }} (<span class="change-lang__code">{{ slotProps.option.code }}</span
				>)
			</div>
		</template>
	</Dropdown>
</template>

<script setup>
	import { $useFetch } from "~/composables/useFetch.js";
	import { api } from "~/api/api.js";
	import { useStatusStore } from "~/store/statuses/statuses";

	const { locale, locales, setLocale } = useI18n();

	const { data: session } = useAuth();

	const currentLocale = computed(() => locales.value.find((el) => el.code === locale.value));

	const selectedCountry = ref(currentLocale.value);

	const { setStatuses } = useStatusStore();

	const { data: statuses, refresh: getLangStatuses } = await $useFetch(`${api.orderStatuses}`, {
		key: "lang",
		method: "GET",
		params: {
			lang: computed(() => selectedCountry.value.code),
		},
		immediate: false,
		watch: false,
		transform: (mutation) => mutation.data,
	});

	watch(
		() => selectedCountry.value,
		(val) => {
			setLocale(val.code);
		},
	);

	watch(
		locale,
		async () => {
			if (!session.value) return;

			await getLangStatuses();
			setStatuses(statuses.value);
		},
		{
			immediate: true,
		},
	);
</script>

<style lang="scss">
	@import "change-lang";
</style>
